import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DecisionData, NAVI_STEP_ORDER, NaviStep, NaviSubStep } from '@entscheidungsnavi/decision-data';
import { Observable, Subject } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { calculateCurrentProgress, numberToSubStep, subStepToNumber } from '@entscheidungsnavi/decision-data/progress';
import { ProjectService } from '../../../app/data/project';
import { EDUCATIONAL_STEPS_META_DATA, urlToEducationalSubStep } from './educational-navigation';

@Injectable({
  providedIn: 'root',
})
export class EducationalNavigationService {
  // The currently opened step in the Navi
  readonly activeStep: Observable<NaviSubStep | null>;

  // The current progress in the project
  currentProgress: NaviSubStep;

  readonly currentProgressUpdate$ = new Subject<void>();

  constructor(
    private decisionData: DecisionData,
    projectService: ProjectService,
    private router: Router,
  ) {
    projectService.project$.subscribe(() => {
      this.currentProgress = undefined;
      this.updateProgress();
    });

    this.activeStep = router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map(event => urlToEducationalSubStep(event.url)),
      shareReplay(1),
    );

    this.decisionData.objectiveAdded$.subscribe(() => {
      const currentProgress = this.currentProgress;
      if (currentProgress.step === 'results' && currentProgress.subStepIndex == null) {
        this.decisionData.resultSubstepProgress = 1;
        this.unlockProgress();
      }
    });
  }

  updateProgress() {
    const calculatedProgress = calculateCurrentProgress(this.decisionData);
    if (this.currentProgress == null) {
      this.currentProgress = calculatedProgress;
    } else {
      // Progress can never go backward
      this.currentProgress = numberToSubStep(Math.max(subStepToNumber(this.currentProgress), subStepToNumber(calculatedProgress)));
    }

    this.currentProgressUpdate$.next();
  }

  unlockProgress() {
    this.currentProgress = undefined;
    this.updateProgress();
  }

  /**
   * Manually override the current progress. Used in the enforce-gradual-progression guard when steps are skipped.
   */
  confirmSkip(toStep: NaviSubStep) {
    this.currentProgress = toStep;
    this.updateProgress();
  }

  /**
   * Navigates to a specific step in educational mode.
   *
   * @param step - The step to navigate to
   * @returns An observable that yields true iff the navigation succeeded
   */
  navigateToStep(step: NaviStep): Promise<boolean> {
    const stepIndex = NAVI_STEP_ORDER.indexOf(step);

    const currentStepName = this.currentProgress.step;
    const currentStepIndex = NAVI_STEP_ORDER.indexOf(currentStepName);

    if (step === 'results' && this.currentProgress.subStepIndex === undefined && !this.decisionData.validateWeights()[0]) {
      // Results
      this.decisionData.resultSubstepProgress = 1;
      this.currentProgress.subStepIndex = 1;
    }

    const mainStepUrl = EDUCATIONAL_STEPS_META_DATA[step].routerLink;

    if (
      step === 'impactModel' ||
      stepIndex < currentStepIndex ||
      (stepIndex === currentStepIndex && this.currentProgress.subStepIndex === undefined) ||
      step === 'finishProject'
    ) {
      return this.router.navigate([mainStepUrl]);
    } else if (stepIndex === currentStepIndex) {
      return this.router.navigate([mainStepUrl, 'steps', this.currentProgress.subStepIndex + 1]);
    } else {
      return this.router.navigate([mainStepUrl, 'steps', 1]);
    }
  }
}
